import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import NewLeadForm from './Containers/NewLeadForm/NewLeadForm';
import RenewForm from './Containers/RenewForm/RenewForm';
import NotFound from './Containers/NotFound/NotFound';

const prod = true;

const ligaAPI = prod ? "https://api.rgasesores.com.mx/" : "http://localhost:4000/";

const Routes = () => {
    return (
        <Switch>
            <Redirect from='/' exact to='nuevos'/>
            <Route path='/nuevos' render={() => <NewLeadForm APIURL={ligaAPI} /> } />
            <Route path='/renovaciones' render={() => <RenewForm APIURL={ligaAPI} /> } />
            <Route path='/not-found' render={() => <NotFound /> }/>
            <Redirect from='/' to='/not-found'/>
        </Switch>
    )
}

export default Routes;
