import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import InputMask from 'react-input-mask';
import CurrencyFormat from 'react-currency-format';
import CotizadorRG from 'cotizador-rg';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import './RenewForm.scss';

import Button from '../../Components/Button/Button';
import PopUpMessage from '../../Components/PopUpMessage/PopUpMessage';

const Cotizador = new CotizadorRG(0, "CDMX", "CDMX", [
  "firm",
  "gest",
  "invsol",
  "irpp",
  "recad",
  "recinm",
  "sdm"
]);

const formatter = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
  minimumFractionDigits: 2
});

let dueDate = new Date();
const numberOfDaysToAdd = 5;
dueDate.setDate(dueDate.getDate() + numberOfDaysToAdd); 

const RenewForm = (props) => {
  const [formFields, setFormFields] = useState({
    id: "",
    nombre: "",
    correo: "",
    celular: "",
    celularSinFormato: "",
    calle: "",
    exterior: "",
    interior: "",
    cp: "",
    cpNotFound: false,
    opcionesColonias: [],
    colonia: "",
    municipio: "",
    ciudad: "",
    pais: "México",
    montoAnterior: 0,
    monto: 0,
    fiador: true,
    seguro: true,
    fechaFirma: dueDate,
    dateNoFormat: `${dueDate.getFullYear()}-${dueDate.toLocaleString('es-MX', {month: '2-digit'})}-${dueDate.toLocaleString('es-MX', {day: '2-digit'})}T12:00`
  });
  const [resultado, setResultado] = useState();
  const [recap, setRecap] = useState(false);
  const [errores, setErrores] = useState({
    mensaje: "",
    existe: false
  });
  const [inputStyles, setInputStyles] = useState({});
  const [enviado, setEnviado] = useState({
    mensaje: "",
    existe: false
  });
  const [enviando, setEnviando] = useState(false);
  const [cancelando, setCancelando] = useState({
    isCancelando: false,
    razon: "",
    problema: false,
    detalles: ""
  });

  useEffect(() => {

    async function fetchVars(){

      let loadedCotizador = await Cotizador.promesaInicio;

      if(loadedCotizador === 'Init Done'){
        const valores = queryString.parse(props.location.search);

        const {
          id,
          nombre,
          correo,
          celular,
          calle,
          exterior,
          interior,
          monto,
          fiador,
          seguro
        } = valores;

        if (id 
          && nombre &&
          correo &&
          calle &&
          monto &&
          fiador &&
          seguro
        ) {
          const newForm = {
            id,
            nombre,
            correo,
            celular: celular ? `${celular.substr(0,3)} (${celular.substr(3,2)}) ${celular.substr(5,4)} ${celular.substr(9,4)}` : "",
            celularSinFormato: celular ? celular : "",
            calle,
            exterior: exterior ? exterior : "",
            interior: interior ? interior : "",
            cp: "",
            montoAnterior: parseInt(monto),
            monto: 0,
            fiador: fiador === "true" ? true : false,
            seguro: seguro === "true" ? true : false,
            cpNotFound: false,
            opcionesColonias: [],
            colonia: "",
            municipio: "",
            ciudad: "",
            pais: "México",
            fechaFirma: dueDate,
            dateNoFormat: `${dueDate.getFullYear()}-${dueDate.toLocaleString('es-MX', {month: '2-digit'})}-${dueDate.toLocaleString('es-MX', {day: '2-digit'})}T12:00`
          };

          setFormFields(newForm);

          if (!newForm.fiador){
            Cotizador.toggleProduct("irpp");
          }
          
          if (!newForm.seguro) {
            Cotizador.toggleProduct("sdm");
          }

          Cotizador.changeVariable("R", newForm.monto);
        }

        setResultado(Cotizador.calcularPrecio());
      } else {
        console.log('Error al inicar cotizador');
      }
    }

    fetchVars();

    setTimeout(() => {
      window.grecaptcha.render('recaptcha', {
        'sitekey': '6LfWZ6cUAAAAALAZtqu7qGVUP_qSa9xIY6Dw-HHD',
        'callback': (token) => {
  
          const data = {
            responseToken: token
          }
  
          fetch(`${props.APIURL}servicios/recaptcha-verify`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
              'Content-Type': 'application/json'
            }
          }).then(response => {return response.json()})
            .then(data => {
              data = JSON.parse(data);
              if(data.success){
                setRecap(true);
              } else {
                return Promise.reject('error');
              }
            })
            .catch(err => {
              if(err["error-codes"]){
                console.log(err);
              } else {
                console.log(err);
              }
            });
        }
      });
    }, 500);
  }, [props.location.search, props.APIURL]);

  const onFieldChange = (nombre, valor) => {
    let newFields = {
      ...formFields
    }

    if (nombre === "monto" && valor !== "") {
      let valorNumerico = valor.substr(1);
      valorNumerico=valorNumerico.replace(/,/g,'');
      valor = parseFloat(valorNumerico);
      Cotizador.changeVariable("R", valor);
      setResultado(Cotizador.calcularPrecio());
    } else if (nombre === "fiador") {
      Cotizador.toggleProduct("irpp");
      setResultado(Cotizador.calcularPrecio());
    } else if (nombre === "seguro") {
      Cotizador.toggleProduct("sdm");
      setResultado(Cotizador.calcularPrecio());
    } else if (nombre === "celular") {
      const valorSinFormato = valor.replace(/\(/g, '').replace(/\)/g, '').replace(/  */g, '');
      newFields.celularSinFormato = valorSinFormato;
    } else if (nombre === "fechaFirma") {
      let dia = new Date(valor + "Z-05:00");
      newFields.dateNoFormat = valor;
      valor = dia;
    }

    

    newFields[nombre] = valor;
    setFormFields(newFields);
  }

  const onCancelChange = (nombre, valor) => {
    let newCancelFields = {
      ...cancelando
    }

    newCancelFields[nombre] = valor;
    setCancelando(newCancelFields);
  }

  const handleZipChange = (zip) => {
    if(zip === ""){
      return setFormFields({
        ...formFields,
        cpNotFound: true,
        opcionesColonias: [],
        colonia: "",
        municipio: "",
        ciudad: ""
      });
    }
    fetch(`https://api-codigos-postales.herokuapp.com/v2/codigo_postal/${zip}`)
      .then(response=> {
        return response.json();
      })
      .then(data=>{
        if(!data.estado){
          return Promise.reject('No se encontraron Colonias');
        }
        setFormFields({
          ...formFields,
          cpNotFound: false,
          opcionesColonias: data.colonias,
          colonia: "",
          municipio:  data.municipio,
          ciudad: data.estado
        });
      })
      .catch(err=>{
        setFormFields({
          ...formFields,
          cpNotFound: true,
          opcionesColonias: [],
          colonia: "",
          municipio: "",
          ciudad: ""
        });
      });
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setEnviando(true);

    if(!recap){
      setErrores({
        mensaje: "Asegurate de no ser un robot",
        existe: true
      });

      setEnviando(false);

      return;
    }

    if (formFields.nombre === ""
      || formFields.correo === ""
      || formFields.celular === ""
      || formFields.calle === ""
      || formFields.exterior === ""
      || formFields.cp === ""
      || formFields.colonia === ""
      || formFields.monto <= 1000
      || formFields.id === ""
      || formFields.fechaFirma.getTime() < new Date().getTime()
    ) {
      setInputStyles({
        borderColor: 'red',
        borderWidth: '3px'
      });

      setErrores({
        mensaje: "Por favor llena todos los campos obligatorios marcados en rojo y asegurate que la renta sea mayor a $1,000",
        existe: true
      });

      setEnviando(false);

      return;
    }

    const body = {
      ...formFields,
      ...resultado
    }

    try {
      let resultadoGuardado = await fetch(`${props.APIURL}servicios/solicitud-renovacion`, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(body), // data can be `string` or {object}!
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(resp => resp.json());

      if(resultadoGuardado) {
        
        setEnviando(false);
        setEnviado({
          mensaje: `¡Gracias! Tus datos han sido recibidos, la documentación requerida por parte de tu(s) prospecto(s) inquilino(s) la hemos enviado a tu cuenta de correo ${formFields.correo}.

          Nos pondremos en contacto a la brevedad, gracias por tu preferencia!`,
          existe: true
        })
      }
    } catch(error) {
      console.log(error);
      setEnviando(false);
      setErrores({
        mensaje: JSON.stringify(error),
        existe: true
      });
    }
    
  }

  const handleCancelar = async () => {
    setEnviando(true);
    let body;

    if(cancelando.razon !== ""){
      body = {
        ...formFields,
        ...resultado,
        ...cancelando
      }
    }
    
    try {
      let resultadoCancelar = await fetch(`${props.APIURL}servicios/solicitud-cancelacion`, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(body), // data can be `string` or {object}!
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(resp => resp.json());

      if(resultadoCancelar) {
        setEnviando(false);
        
        setCancelando({
          isCancelando: false,
          razon: "",
          problema: false,
          detalles: ""
        });

        setEnviado({
          mensaje: `¡Gracias! Tus solicitud de cancelación del contrato ${formFields.id} ha sido recibida. Pronto nos pondremos en contacto`,
          existe: true
        })
      }
    } catch(error){
      console.log(error);
      setCancelando({
        isCancelando: false,
        razon: "",
        problema: false,
        detalles: ""
      });
      setErrores({
        mensaje: JSON.stringify(error),
        existe: true
      });
    }
  }

  const addToRenta = () => {
    const newRenta = formFields.montoAnterior * 1.06;
    setFormFields({
      ...formFields,
      monto: newRenta
    });
    Cotizador.changeVariable("R", newRenta);
    setResultado(Cotizador.calcularPrecio());
  }

  const handleModalClose = () => {
    setEnviado({
      mensaje: "",
      existe: false
    })
  }

  const handleCancelClose = () => {
    setCancelando({
      isCancelando: false,
      razon: "",
      problema: false,
      detalles: ""
    })
  }

  const colorObligatorio = '#9E246F';

  return (
    <section className="Renovacion-Form__Section">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Renovación de Cobertura | RG Asesores</title>
        <link rel="canonical" href="http://forms.rgasesores.com.mx/renovaciones" />
        <meta name="description" content="Utiliza este formulario para renovar tu cobertura y programar una fecha de firma tentativa." />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Renovación de Cobertura | RG Asesores"/>
        <meta property="og:description" content="Utiliza este formulario para renovar tu cobertura y programar una fecha de firma tentativa."/>
        <meta property="og:image" content="https://res.cloudinary.com/rg-asesores/image/upload/v1559875852/Branding/logo-RG_kessdt.png"/>
        <meta property="og:url" content="http://forms.rgasesores.com.mx/renovaciones"/>
      </Helmet>
      <div className="rg-card Renovacion-Form__Main">
        <form className="rg-form" onSubmit={handleSubmit}>
          <h2 className="form-title">Renovación de Cobertura</h2>
          <p>Llena los siguientes datos para renovar tu cobertura y programar una fecha de firma tentativa. Pronto nos pondremos en contacto.</p>
          <p style={{color: colorObligatorio}}><small>Campos requeridos <strong>º</strong></small></p>
          <h3 className="form-sub-title">Datos de la Renta</h3>
          <div className="form-field desktop-half">
            <p><strong>Renta anterior:</strong> {formatter.format(formFields.montoAnterior)}</p>
            <label htmlFor="monto">Nuevo Monto de Renta <span style={{color: colorObligatorio}}>º</span></label>
            <CurrencyFormat
              thousandSeparator={true}
              prefix={'$ '} 
              value={formFields.monto}
              onChange={(e) => onFieldChange("monto", e.target.value)} style={inputStyles}/>
            <Button type="Secondary" onClick={addToRenta} >Renta Anterior + 6%</Button>
          </div>
          <div className="form-field desktop-half">
            <label>Extras</label>
            <input 
              type="checkbox"
              name="fiador"
              value="fiador"
              checked={formFields.fiador}
              onChange={(e) => onFieldChange("fiador", e.target.checked)}/> Requiero Fiador 🤝<br/>
            <input
              type="checkbox"
              name="seguro"
              value="seguro"
              checked={formFields.seguro}
              onChange={(e) => onFieldChange("seguro", e.target.checked)}/> Requiero seguro contra daños físicos mayores 🔥
          </div>
          <h3 className="form-sub-title">Datos del Contrato</h3>
          <div className="form-field desktop-half">
            <label htmlFor="idArrenda">Número de Arrendamiento <span style={{color: colorObligatorio}}>º</span></label>
            <input type="text" name="idArrenda" placeholder="RS1234" value={formFields.id} style={inputStyles} onChange={(e) => {onFieldChange("id", e.target.value)}}/>
            <small>Folio interno de RG para identificar los contratos. Puedes encontrarlo en tu poliza.</small>
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="fechaFirma">Fecha Tentativa de Firma <span style={{color: colorObligatorio}}>º</span></label>
            <input type="datetime-local" className="rg-force-format" name="fechaFirma" value={formFields.dateNoFormat} style={inputStyles} onChange={(e) => {onFieldChange("fechaFirma", e.target.value)}}/>
          </div>
          <h3 className="form-sub-title">Datos de Contacto</h3>
          <div className="form-field">
            <label htmlFor="nombre">Nombre Completo <span style={{color: colorObligatorio}}>º</span></label>
            <input type="text" name="nombre" placeholder="Nombre completo con apellidos" value={formFields.nombre} onChange={(e) => {onFieldChange("nombre", e.target.value)}} style={inputStyles}/>
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="correo"><i className="far fa-envelope"></i> Correo <span style={{color: colorObligatorio}}>º</span></label>
            <input type="email" name="correo" placeholder="hola@rgasesores.com.mx" value={formFields.correo} onChange={(e) => {onFieldChange("correo", e.target.value)}} style={inputStyles}/>
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="celular"><i className="fas fa-mobile-alt"></i> Celular <span style={{color: colorObligatorio}}>º</span></label>
            <InputMask className="rg-force-format" placeholder="+52 (55) 9999 9999" mask="+52 (99) 9999 9999" maskChar=" " value={formFields.celular} onChange={(e) => onFieldChange("celular", e.target.value)} style={inputStyles}/>
          </div>
          <h3 className="form-sub-title">Dirección Del Inmueble a Rentar</h3>
          <div className="form-field">
            <label htmlFor="calle">Calle <span style={{color: colorObligatorio}}>º</span></label>
            <input type="text" name="calle" value={formFields.calle} onChange={(e) => {onFieldChange("calle", e.target.value)}} style={inputStyles}/>
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="exterior"># Exterior <span style={{color: colorObligatorio}}>º</span></label>
            <input type="text" name="exterior" value={formFields.exterior} onChange={(e) => {onFieldChange("exterior", e.target.value)}} style={inputStyles}/>
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="interior"># Interior</label>
            <input type="text" name="interior" value={formFields.interior} onChange={(e) => {onFieldChange("interior", e.target.value)}}/>
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="cp">Código Postal <span style={{color: colorObligatorio}}>º</span></label>
            <input type="text" name="cp" value={formFields.cp} onChange={(e) => {onFieldChange("cp", e.target.value)}} onBlur={(e)=> handleZipChange(e.target.value)} style={inputStyles}/>
            {formFields.cpNotFound && <small style={{color: colorObligatorio}}>No se encontró el código postal</small>}
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="cp">Colonia <span style={{color: colorObligatorio}}>º</span></label>
            <select name="cp" value={formFields.colonia} onChange={(e) => onFieldChange('colonia', e.target.value)} style={inputStyles}>
              <option value="" disabled>--- Selecciona una opción ---</option>
              {formFields.opcionesColonias.map((colonia, key)=>{
                return <option key={key} value={colonia}>{colonia}</option>
              })}
            </select>
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="municipio">Municipio</label>
            <p>{formFields.municipio !== "" ? formFields.municipio : "..."}</p>
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="ciudad">Ciudad</label>
            <p>{formFields.ciudad !== "" ? formFields.ciudad : "..."}</p>
          </div>
          <div id="recaptcha" className="form-field"></div>

          <div className="form-field">
            <Button type={recap ? "Primary" : "Secondary"} Submit={true} size="Big">Enviar Solicitud</Button>
            {errores.existe && <p style={{color: colorObligatorio}}>{errores.mensaje}</p>}
            {enviando && <h4>Enviando y Procesando Datos...</h4>}
          </div>
          <div className="form-field">
            <p>Nota: La documentación requerida por parte del propietario, prospecto inquilino y fiador se te hará llegar a tu dirección de correo {formFields.correo !== "" ? `(${formFields.correo})` : "(el que se proporciona al inicio de este formulario)"}</p>
          </div>
        </form>
      </div>
      <div className="Renovacion__Precio">
        <h3 className="">Precio de Cobertura {formFields.id}</h3>
        {resultado && (
          <div className="">
          <p>
            Precio: {formatter.format(resultado.precio)}<br/>
            IVA: {formatter.format(resultado.iva)}<br/>
            <strong>Precio + IVA: {formatter.format(resultado.precioConIva)}</strong>
          </p>
          {formFields.id && <Button type="Danger" onClick={() => setCancelando({...cancelando, isCancelando: true})} position="Center">Cancelar Contrato</Button>}
          </div>
        )}
      </div>
      <PopUpMessage title="Operación Exitosa" isOpen={enviado.existe} handleClose={handleModalClose}>{enviado.mensaje}</PopUpMessage>
      <PopUpMessage title={`Cancelación de Contrato ${formFields.id}`} isOpen={cancelando.isCancelando} handleClose={handleCancelClose}>
        <form>
          <div className="form-field">
            <label htmlFor="razon">Razón de Cancelación <span style={{color: colorObligatorio}}>º</span></label>
            <select name="razon" value={cancelando.razon} onChange={(e) => onCancelChange('razon', e.target.value)}>
              <option value="" disabled>--- Selecciona una opción ---</option>
              <option>El Inquilino se va a Salir</option>
              <option>El Inquilino me Debe Rentas o Servicios</option>
              <option>Voy a Vender el Inmueble</option>
            </select>
          </div>
          <div className="form-field">
            <input 
              type="checkbox"
              name="problema"
              value="problema"
              checked={cancelando.problema}
              onChange={(e) => onCancelChange("problema", e.target.checked)}/> Requiero Ayuda de un Abogado ⚔️
          </div>
          <div className="form-field">
            <label htmlFor="detalles">Comentarios</label>
            <textarea name="detalles" value={cancelando.detalles} onChange={(e) => onCancelChange('detalles', e.target.value)}></textarea>
          </div>
          <div className="form-field">
            {cancelando.razon !== "" && <Button type="Danger" onClick={handleCancelar} position="Center">Cancelar Contrato</Button>}
            {errores.existe && <p style={{color: colorObligatorio}}>{errores.mensaje}</p>}
            {enviando && <h4>Enviando y Procesando Datos...</h4>}
          </div>
        </form>
      </PopUpMessage>
    </section>
  );
}

export default withRouter(RenewForm);
