import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import CurrencyFormat from 'react-currency-format';
import CotizadorRG from 'cotizador-rg';
import { Helmet } from "react-helmet";
import './NewLeadForm.scss';

import Button from '../../Components/Button/Button';
import PopUpMessage from '../../Components/PopUpMessage/PopUpMessage';

const Cotizador = new CotizadorRG(10000, "CDMX", "CDMX", [
  "firm",
  "gest",
  "invsol",
  "irpp",
  "recad",
  "recinm"
]);

const formatter = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
  minimumFractionDigits: 2
});

const NewLeadForm = ({APIURL}) => {
  const [formFields, setFormFields] = useState({
    nombre: "",
    correo: "",
    celular: "",
    celularSinFormato: "",
    calle: "",
    exterior: "",
    interior: "",
    cp: "",
    cpNotFound: false,
    opcionesColonias: [],
    colonia: "",
    municipio: "",
    ciudad: "",
    pais: "México",
    monto: 10000,
    fiador: true,
    seguro: false
  });
  const [resultado, setResultado] = useState();
  const [recap, setRecap] = useState(false);
  const [errores, setErrores] = useState({
    mensaje: "",
    existe: false
  });
  const [inputStyles, setInputStyles] = useState({});
  const [enviado, setEnviado] = useState({
    mensaje: "",
    existe: false
  });
  const [enviando, setEnviando] = useState(false);

  useEffect(() => {
    async function fetchVars(){

      let loadedCotizador = await Cotizador.promesaInicio;

      if(loadedCotizador === 'Init Done'){
        setResultado(Cotizador.calcularPrecio());
      } else {
        console.log('Error al inicar cotizador');
      }
    }

    fetchVars();

    setTimeout(() => {
      window.grecaptcha.render('recaptcha', {
        'sitekey': '6LfWZ6cUAAAAALAZtqu7qGVUP_qSa9xIY6Dw-HHD',
        'callback': (token) => {
  
          const data = {
            responseToken: token
          }
  
          fetch(`${APIURL}servicios/recaptcha-verify`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
              'Content-Type': 'application/json'
            }
          }).then(response => {return response.json()})
            .then(data => {
              data = JSON.parse(data);
              if(data.success){
                setRecap(true);
              } else {
                return Promise.reject('error');
              }
            })
            .catch(err => {
              if(err["error-codes"]){
                console.log(err);
              } else {
                console.log(err);
              }
            });
        }
      });
    }, 500);
  }, [APIURL]);

  const onFieldChange = (nombre, valor) => {
    let newFields = {
      ...formFields
    }

    if (nombre === "monto" && valor !== "") {
      let valorNumerico = valor.substr(1);
      valorNumerico=valorNumerico.replace(/,/g,'');
      valor = parseFloat(valorNumerico);
      Cotizador.changeVariable("R", valor);
      setResultado(Cotizador.calcularPrecio());
    } else if (nombre === "fiador") {
      Cotizador.toggleProduct("irpp");
      setResultado(Cotizador.calcularPrecio());
    } else if (nombre === "seguro") {
      Cotizador.toggleProduct("sdm");
      setResultado(Cotizador.calcularPrecio());
    } else if (nombre === "celular") {
      const valorSinFormato = valor.replace(/\(/g, '').replace(/\)/g, '').replace(/  */g, '');
      newFields.celularSinFormato = valorSinFormato;
    }

    

    newFields[nombre] = valor;
    setFormFields(newFields);
  }

  const handleZipChange = (zip) => {
    if(zip === ""){
      return setFormFields({
        ...formFields,
        cpNotFound: true,
        opcionesColonias: [],
        colonia: "",
        municipio: "",
        ciudad: ""
      });
    }
    fetch(`https://api-codigos-postales.herokuapp.com/v2/codigo_postal/${zip}`)
      .then(response=> {
        return response.json();
      })
      .then(data=>{
        if(!data.estado){
          return Promise.reject('No se encontraron Colonias');
        }
        setFormFields({
          ...formFields,
          cpNotFound: false,
          opcionesColonias: data.colonias,
          colonia: "",
          municipio:  data.municipio,
          ciudad: data.estado
        });
      })
      .catch(err=>{
        setFormFields({
          ...formFields,
          cpNotFound: true,
          opcionesColonias: [],
          colonia: "",
          municipio: "",
          ciudad: ""
        });
      });
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setEnviando(true);

    if(!recap){
      setErrores({
        mensaje: "Asegurate de no ser un robot",
        existe: true
      });

      setEnviando(false);

      return;
    }

    if (formFields.nombre === ""
      || formFields.correo === ""
      || formFields.celular === ""
      || formFields.calle === ""
      || formFields.exterior === ""
      || formFields.cp === ""
      || formFields.colonia === ""
      || formFields.monto <= 1000
    ) {
      setInputStyles({
        borderColor: 'red',
        borderWidth: '3px'
      });

      setErrores({
        mensaje: "Por favor llena todos los campos obligatorios marcados en rojo y asegurate que la renta sea mayor a $1,000",
        existe: true
      });

      setEnviando(false);

      return;
    }

    const body = {
      ...formFields,
      ...resultado
    }

    try {
      let resultadoGuardado = await fetch(`${APIURL}servicios/solicitud-nuevos`, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(body), // data can be `string` or {object}!
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(resp => resp.json());

      if(resultadoGuardado.mail.id) {
        setEnviando(false);
        setEnviado({
          mensaje: `¡Gracias! Tus datos han sido recibidos, la documentación requerida por parte de tu(s) prospecto(s) inquilino(s) la hemos enviado a tu cuenta de correo ${formFields.correo}.

          Nos pondremos en contacto a la brevedad, gracias por tu preferencia!`,
          existe: true
        })
      }
    } catch(error) {
      console.log(error);
      setEnviando(false);
      setErrores({
        mensaje: JSON.stringify(error),
        existe: true
      });
    }
    
  }

  const handleModalClose = () => {
    setEnviado({
      mensaje: "",
      existe: false
    })
  }

  const colorObligatorio = '#9E246F';

  return (
    <section className="New-Lead-Form__Section">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Solicitud de Cobertura | RG Asesores</title>
        <link rel="canonical" href="http://forms.rgasesores.com.mx/nuevos" />
        <meta name="description" content="Utiliza este formulario para solicitar nuestra cobertura y recibir los requisitos en tu correo." />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Solicitud de Cobertura | RG Asesores"/>
        <meta property="og:description" content="Utiliza este formulario para solicitar nuestra cobertura y recibir los requisitos en tu correo."/>
        <meta property="og:image" content="https://res.cloudinary.com/rg-asesores/image/upload/v1559875852/Branding/logo-RG_kessdt.png"/>
        <meta property="og:url" content="http://forms.rgasesores.com.mx/nuevos"/>
      </Helmet>
      <div className="rg-card New-Lead-Form__Main">
        <form className="rg-form" onSubmit={handleSubmit}>
          <p style={{color: colorObligatorio}}><small>Campos requeridos <strong>º</strong></small></p>
          <h3 className="form-sub-title">Datos de la Renta</h3>
          <div className="form-field desktop-half">
            <label htmlFor="monto">Monto de Renta <span style={{color: colorObligatorio}}>º</span></label>
            <CurrencyFormat
              thousandSeparator={true}
              prefix={'$ '} 
              value={formFields.monto}
              onChange={(e) => onFieldChange("monto", e.target.value)} style={inputStyles}/>
          </div>
          <div className="form-field desktop-half">
            <label>Extras</label>
            <input 
              type="checkbox"
              name="fiador"
              value="fiador"
              checked={formFields.fiador}
              onChange={(e) => onFieldChange("fiador", e.target.checked)}/> Requiero Fiador 🤝<br/>
            <input
              type="checkbox"
              name="seguro"
              value="seguro"
              checked={formFields.seguro}
              onChange={(e) => onFieldChange("seguro", e.target.checked)}/> Requiero seguro contra daños físicos mayores 🔥
          </div>
          <h2 className="form-title">Solicita tu Cobertura</h2>
          <p>Llena los siguientes datos para solicitar tu cobertura y pronto nos pondremos en contacto.</p>
          <h3 className="form-sub-title">Datos de Contacto</h3>
          <div className="form-field">
            <label htmlFor="nombre">Nombre Completo <span style={{color: colorObligatorio}}>º</span></label>
            <input type="text" name="nombre" placeholder="Nombre completo con apellidos" value={formFields.nombre} onChange={(e) => {onFieldChange("nombre", e.target.value)}} style={inputStyles}/>
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="correo"><i className="far fa-envelope"></i> Correo <span style={{color: colorObligatorio}}>º</span></label>
            <input type="email" name="correo" placeholder="hola@rgasesores.com.mx" value={formFields.correo} onChange={(e) => {onFieldChange("correo", e.target.value)}} style={inputStyles}/>
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="celular"><i className="fas fa-mobile-alt"></i> Celular <span style={{color: colorObligatorio}}>º</span></label>
            <InputMask className="rg-force-format" placeholder="+52 (55) 9999 9999" mask="+52 (99) 9999 9999" maskChar=" " value={formFields.celular} onChange={(e) => onFieldChange("celular", e.target.value)} style={inputStyles}/>
          </div>
          <h3 className="form-sub-title">Dirección Del Inmueble a Rentar</h3>
          <div className="form-field">
            <label htmlFor="calle">Calle <span style={{color: colorObligatorio}}>º</span></label>
            <input type="text" name="calle" value={formFields.calle} onChange={(e) => {onFieldChange("calle", e.target.value)}} style={inputStyles}/>
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="exterior"># Exterior <span style={{color: colorObligatorio}}>º</span></label>
            <input type="text" name="exterior" value={formFields.exterior} onChange={(e) => {onFieldChange("exterior", e.target.value)}} style={inputStyles}/>
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="interior"># Interior</label>
            <input type="text" name="interior" value={formFields.interior} onChange={(e) => {onFieldChange("interior", e.target.value)}}/>
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="cp">Código Postal <span style={{color: colorObligatorio}}>º</span></label>
            <input type="text" name="cp" value={formFields.cp} onChange={(e) => {onFieldChange("cp", e.target.value)}} onBlur={(e)=> handleZipChange(e.target.value)} style={inputStyles}/>
            {formFields.cpNotFound && <small style={{color: colorObligatorio}}>No se encontró el código postal</small>}
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="cp">Colonia <span style={{color: colorObligatorio}}>º</span></label>
            <select name="cp" value={formFields.colonia} onChange={(e) => onFieldChange('colonia', e.target.value)} style={inputStyles}>
              <option value="" disabled>--- Selecciona una opción ---</option>
              {formFields.opcionesColonias.map((colonia, key)=>{
                return <option key={key} value={colonia}>{colonia}</option>
              })}
            </select>
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="municipio">Municipio</label>
            <p>{formFields.municipio !== "" ? formFields.municipio : "..."}</p>
          </div>
          <div className="form-field desktop-half">
            <label htmlFor="ciudad">Ciudad</label>
            <p>{formFields.ciudad !== "" ? formFields.ciudad : "..."}</p>
          </div>
          <div id="recaptcha"></div>

          <div className="form-field">
            <Button type={recap ? "Primary" : "Secondary"} Submit={true} size="Big">Enviar Solicitud</Button>
            {errores.existe && <p style={{color: colorObligatorio}}>{errores.mensaje}</p>}
            {enviando && <h4>Enviando y Procesando Datos...</h4>}
          </div>

          <div className="form-field">
            <p>Nota: La documentación requerida por parte del propietario, prospecto inquilino y fiador se te hará llegar a tu dirección de correo {formFields.correo !== "" ? `(${formFields.correo})` : "(el que se proporciona al inicio de este formulario)"}</p>
          </div>
        </form>
      </div>
      <div className="New-Lead__Precio">
        <h3 className="">Precio de Cobertura</h3>
        <div className="">
          {resultado && (
            <p>
              Precio: {formatter.format(resultado.precio)}<br/>
              IVA: {formatter.format(resultado.iva)}<br/>
              <strong>Precio + IVA: {formatter.format(resultado.precioConIva)}</strong>
            </p>
          )}
        </div>
      </div>
      <PopUpMessage title="Operación Exitosa" isOpen={enviado.existe} handleClose={handleModalClose}>{enviado.mensaje}</PopUpMessage>
    </section>
  );
}

export default NewLeadForm;
