import React from 'react';
import {Link} from 'react-router-dom';
import './NotFound.scss';

const NotFound = () => {
  return (
    <section className="Not-Found__Section">
        <div className="rg-text">
            <h2>404 Página No Encontrada</h2>
            <p>La página que solicitaste no se ha encontrado, por favor regresa a la <Link to="/">página de inicio.</Link></p>
        </div>
    </section>
  );
}

export default NotFound;